import { useTranslate } from '@pankod/refine-core'
import { Alert, Form, Modal, ModalProps, Select } from '@pankod/refine-antd'
import { useEffect, useState } from 'react'
import { ENDPOINT_TIPSA, API_URL, SHIPMENT_TYPE_CONSOLIDATED, SHIPMENT_TYPE_DIRECT, GRAN_CANARIA_ISLAND_ID, TENERIFE_ISLAND_ID, LANZAROTE_ISLAND_ID, FUERTEVENTURA_ISLAND_ID, LA_PALMA_ISLAND_ID, LA_GRACIOSA_ISLAND_ID, LA_GOMERA_ISLAND_ID, EL_HIERRO_ISLAND_ID, SUPERUSER_SHIPMENT_LIST, ENDPOINT_ONTIME } from 'utility/constants'
import CustomSkeleton from 'components/feedback/CustomSkeleton'
import { supabaseClient } from 'utility'

type ProcessModalProps = {
  modalProps: ModalProps,
  close: any,
  orderId: number | undefined,
  setOrderId?: Function | undefined,
  currentOrderInfo: any | null
  setCurrentOrderInfo?: Function | undefined
  tableQuery?: any
}

export const ProcessModal: React.FC<ProcessModalProps> = ({ modalProps, close, orderId, setOrderId, currentOrderInfo, setCurrentOrderInfo, tableQuery }) => {
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [shipperEndpoint, setShipperEndpoint] = useState('')
  const [shipperLabel, setShipperLabel] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [loading, setLoading] = useState(true)
  const [shippedBy, setShippedBy] = useState<string | undefined>(undefined)
  const t = useTranslate()

  const userEmail = supabaseClient.auth.user()?.email

  useEffect(() => {
    if (orderId) applyAutoShipmentRules()
  }, [orderId, currentOrderInfo])

  const applyAutoShipmentRules = async () => {
    await applyRules()
  }

  const applyRules = async () => {
    setShippedBy(undefined)
    // TODO APPLY RULES
    const orderData = (currentOrderInfo.order) ? currentOrderInfo.order : currentOrderInfo

    if (orderData.id === orderId) {
      // if express -> tipsa express

      const islandId = (orderData.shipping_address_id?.island_id) ? orderData.shipping_address_id.island_id : orderData.shipping_address.island_id

      if (orderData?.shipping_method?.name?.toLowerCase().includes('express')) {
        setShipperEndpoint(`${ENDPOINT_TIPSA}/${orderId}/${SHIPMENT_TYPE_DIRECT}`)
        setShipperLabel(t('shipments.methods.tipsaMainland'))
        setLoading(false)
        setShippedBy('TIPSA PENÍNSULA')
      } else {
        // // if economy or standard
        if ([GRAN_CANARIA_ISLAND_ID].includes(islandId)) {
          // if GC, LZ, FV, LA GRACIOSA -> TIPSA GC
          setShipperEndpoint(`${ENDPOINT_TIPSA}/${orderId}/${SHIPMENT_TYPE_CONSOLIDATED}?agency=gc`)
          setShippedBy('TIPSA GC')
          setShipperLabel(t('shipments.methods.tipsaGC'))
          setLoading(false)
        } else if ([LANZAROTE_ISLAND_ID, FUERTEVENTURA_ISLAND_ID, LA_GRACIOSA_ISLAND_ID].includes(islandId)) {
          setShipperEndpoint(`${ENDPOINT_ONTIME}/${orderId}`)
          setShippedBy('ONTIME')
          setShipperLabel(t('shipments.methods.ontime'))
          setLoading(false)
        } else if ([TENERIFE_ISLAND_ID, LA_PALMA_ISLAND_ID, LA_GOMERA_ISLAND_ID, EL_HIERRO_ISLAND_ID].includes(islandId)) {
          // if TF, LA PALMA, EL HIERRO, LA GOMERA -> TIPSA TF
          setShipperEndpoint(`${ENDPOINT_TIPSA}/${orderId}/${SHIPMENT_TYPE_CONSOLIDATED}?agency=tf`)
          setShippedBy('TIPSA TF')
          setShipperLabel(t('shipments.methods.tipsaTF'))
          setLoading(false)
        }
        // }
      }
    }

    setTimeout(() => setLoading(false), 5000)
  }

  const getErrorDescription = (msg: string): string => {
    if (msg === 'Uncomplete data') return t('shipments.errors.uncompletedData')
    return msg
  }

  const handleOk = async () => {
    setConfirmLoading(true)
    try {
      const response = await fetch(`${API_URL}/${shipperEndpoint}`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        },
        // TODO: Set same cookies as web when login into the dashboard
        credentials: 'include'
      })

      if (!response.ok) {
        const message = await response.text()
        setErrorMsg(getErrorDescription(message))
      } else {
        const data = await response.json()

        await supabaseClient.from('shipments').update({ shipped_by: shippedBy }).eq('order_id', orderId)

        if (data.error) {
          setErrorMsg(getErrorDescription(data.message))
        } else {
          setConfirmLoading(false)
          close()
          setErrorMsg('')
          if (setOrderId) setOrderId(0)
          if (setCurrentOrderInfo) setCurrentOrderInfo(null)
          setLoading(true)
          setShipperEndpoint('')
          if (tableQuery) tableQuery.refetch()
        }
      }
    } catch (e) {
      setErrorMsg('something wrong')
      if (setOrderId) setOrderId(0)
      if (setCurrentOrderInfo) setCurrentOrderInfo(null)
      setLoading(true)
      setShipperEndpoint('')
    } finally {
      setConfirmLoading(false)
      setShippedBy(undefined)
    }
  }

  return (
    <Modal
      {...modalProps}
      title={t('shipments.actions.processShipment')}
      centered
      onOk={handleOk}
      onCancel={() => {
        setErrorMsg('')
        if (setOrderId) setOrderId(0)
        if (setCurrentOrderInfo) setCurrentOrderInfo(null)
        setShipperEndpoint('')
        setLoading(true)
        close()
        if (tableQuery) tableQuery.refetch()
      }}
      confirmLoading={confirmLoading}
    >
      <CustomSkeleton loading={!loading} children={
        <>
          <b>{t('shipments.actions.autoMessageInfo', { shipper: shipperLabel })}</b>
        </>
      } />
      {(userEmail && SUPERUSER_SHIPMENT_LIST.includes(userEmail)) &&
      <Form style={{ marginTop: '2rem' }} layout='vertical'>
        <Form.Item
          label={t('shipments.actions.chooseShipper')}
        >
          <Select
          value={shipperEndpoint}
            onChange={(value) => {
              setShipperEndpoint(value)
              if (value.includes('agency=gc')) {
                setShippedBy('TIPSA GC')
                setShipperLabel(t('shipments.methods.tipsaGC'))
              } else if (value.includes('agency=tf')) {
                setShippedBy('TIPSA TF')
                setShipperLabel(t('shipments.methods.tipsaTF'))
              } else if (value.toLowerCase().includes('directo')) {
                setShippedBy('TIPSA PENÍNSULA')
                setShipperLabel(t('shipments.methods.tipsaMainland'))
              } else if (value.toLowerCase().includes('ontime')) {
                setShippedBy('ONTIME')
                setShipperLabel(t('shipments.methods.ontime'))
              }
            }}
            options={[
              { value: '', label: '-----------------' },
              { value: `${ENDPOINT_TIPSA}/${orderId}/${SHIPMENT_TYPE_CONSOLIDATED}?agency=gc`, label: t('shipments.methods.tipsaGC') },
              { value: `${ENDPOINT_TIPSA}/${orderId}/${SHIPMENT_TYPE_CONSOLIDATED}?agency=tf`, label: t('shipments.methods.tipsaTF') },
              { value: `${ENDPOINT_TIPSA}/${orderId}/${SHIPMENT_TYPE_DIRECT}`, label: t('shipments.methods.tipsaExpress') },
              { value: `${ENDPOINT_ONTIME}/${orderId}`, label: t('shipments.methods.ontime') }
              // { value: `${ENDPOINT_CORREOS}/${orderId}/${CORREOS_SERVICE_TYPE_STANDARD_STR}`, label: t('shipments.methods.correosStandard') },
              // { value: `${ENDPOINT_CORREOS}/${orderId}/${CORREOS_SERVICE_TYPE_PREMIUM_STR}`, label: t('shipments.methods.correosExpress') }
            ]}
          />
        </Form.Item>
      </Form>}
      {errorMsg && (
        <Alert
          message={'❌ Error:'}
          description={errorMsg}
          type="error"
        />
      )}
    </Modal>
  )
}
