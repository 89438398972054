import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilter
} from '@pankod/refine-core'

import {
  List,
  Table,
  useTable,
  EditButton,
  Button,
  ShowButton,
  Tag,
  useModal,
  ExportButton,
  FilterDropdownProps,
  FilterDropdown,
  DateField,
  Select,
  Icons,
  Badge,
  Tooltip,
  message,
  Space,
  Input
} from '@pankod/refine-antd'

import { ProcessModal } from './processModal'

import { definitions, Shipment } from 'interfaces'
import { ADMITTED_STATUS, API_URL, CANCEL_STATUS_TEXT, DECREASE_RETURN_STATUS_TEXT, DEFAULT_DATE_TIME_FORMAT, DELIVERED_STATUS_TEXT, INTERNAL_SPENDING_RETURN_STATUS_TEXT, NO_STOCK_STATUS_TEXT, PARCIAL_RETURN_STATUS_TEXT, PENALIZATION_STATUS_TEXT, PENALIZATION_STATUS_TEXT_SPA, PENDING_PAYMENT_STATUS_TEXT, PREPARED_STATUS, PROCESS_STATUS_TEXT, REFUND_STATUS_TEXT, REQUEST_STATUS_TEXT, RETURN_STATUS_TEXT, SHIPPED_STATUS_TEXT, SUPPLEMENT_STATUS_TEXT } from 'utility/constants'
import { capitalizeFirstLetter } from 'utility/capitalizeFirstLetter'
import { getColorLabel } from 'utility/colorLabel'
import { useState, useEffect, Key } from 'react'
import { supabaseClient, viewTicket } from 'utility'
import { currency } from 'utility/currency'
import { DateFilterDropdown, QuantityFilterDropdown, SelectFilterDropdown } from 'components/filterDropdown'
import { ModalChangeOrdersStatus } from './changeOrdersStatusModal'
import { ExportToExcelButton } from 'components/ExportToExcelButton/ExportToExcelButton'
import { EditableRowField } from 'components/EditableRowField/EditableRowField'
import { AdviceModal } from 'components/AdviceModal/AdviceModal'
// import { getVariableValue } from 'utility/supabaseApi'

const { Option } = Select

export const ShipmentsNotShippedList: React.FC<IResourceComponentsProps> = () => {
  const defaultFilter: CrudFilter[] = [
    { field: 'order.status_id', operator: 'in', value: [ADMITTED_STATUS, PREPARED_STATUS] },
    { field: 'order.supplements.deleted', operator: 'eq', value: false },
    { field: 'order.supplements.volumetric', operator: 'eq', value: true }
  ]

  const { tableProps, tableQueryResult, filters, setFilters } = useTable<definitions['shipments']>({
    initialFilter: defaultFilter,
    metaData: {
      select:
        'id, order_id, tracking_id, estimated_arrival_date, shipped_by, type, delivery_note, extra_cost, ticket_name, shipper_type, processed_at,' +
        'order!inner(id, status_id, prev_status_id, alias, status(id, name), shipping_address_id(id, postal_code, island_id), supplements(id, volumetric, billable_volume), shipping_method(name), syniva_price, comments, package_number, location, invoice, invoice_date)'
    },
    initialSorter: [
      {
        field: 'id',
        order: 'desc'
      }
    ],
    hasPagination: false
  })

  useEffect(() => {
    if (!filters?.find((x: any) => x.field === 'order.status_id')) {
      if (Array.isArray(filterStatusId) && filterStatusId.length > 0) {
        setFilters([
          ...filters || [],
          { field: 'order.status_id', operator: 'in', value: filterStatusId || [ADMITTED_STATUS, PREPARED_STATUS] }
        ])
      } else {
        setFilters(defaultFilter)
      }
    }
  }, [filters])

  const [filterStatusId, setFilterStatusId] = useState<Key[]|undefined>(undefined)

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const { modalProps, show, close } = useModal()
  const [orderId, setOrderId] = useState(0)
  const [currentOrderInfo, setCurrentOrderInfo] = useState(null)
  const [adviseText, setAdviseText] = useState('')
  const {
    modalProps: modalPropsChangeOrder,
    show: showChangeOrder,
    close: closeChangeOrder
  } = useModal()
  const { modalProps: modalPropsAdvice, show: showAdvice, close: closeAdvice } = useModal()

  const [selectedRows, setSelectedRows] = useState<definitions['shipments'][]>([])
  const [idRows, setIdRows] = useState<Key[]>([])
  // const [maxKgDefault, setMaxKgDefault] = useState(0)
  // getVariableValue('MAX_KG_DEFAULT_SHIPPING_PRICE', setMaxKgDefault)

  const dataToExport = (shipments: any[]) => {
    return shipments?.map((shipment: Shipment) => {
      const order = shipment.order
      return {
        [t('shipments.fields.id')]: shipment?.id || '',
        [t('shipments.fields.order_id')]: shipment?.order_id || '',
        [t('shipments.fields.status')]: capitalizeFirstLetter(order?.status?.name) || '',
        [t('order.fields.synivaPrice')]: order?.syniva_price || '',
        [t('order.fields.package_number')]: order?.package_number || '',
        [t('order.fields.location')]: order?.location || '',
        [t('shipments.fields.shipmentMethod')]: order?.shipping_method?.name || '',
        [t('supplement.fields.billableVolume')]: order?.supplements?.find(s => s.volumetric && !s.deleted)?.billable_volume || '',
        [t('shipments.fields.trackingId')]: shipment?.tracking_id || '',
        [t('shipments.fields.type')]: shipment?.shipper_type || '',
        [t('shipments.fields.shipperType')]: shipment?.shipped_by || '',
        [t('shipments.fields.deliveryNote')]: shipment?.delivery_note || '',
        [t('shipments.fields.processed_at')]: shipment?.processed_at ? new Date(shipment?.processed_at) : ''
      }
    })
  }

  const [messageApi, contextHolder] = message.useMessage()

  const generateTicket = async () => {
    const shipmentIds = selectedRows?.map((row) => row.id)?.join(';')

    if (!shipmentIds) {
      messageApi.open({
        type: 'error',
        content: t('notifications.errorMsg')
      })
      return
    }

    const res = await fetch(`${API_URL}/generate-ticket/${shipmentIds}`)
    const { data, error } = await res.json()

    if (!data[0] || error) {
      messageApi.open({
        type: 'error',
        content: t('notifications.errorMsg')
      })
    } else {
      messageApi.open({
        type: 'success',
        content: t('notifications.successMsg')
      })
      tableQueryResult.refetch()
    }
  }

  const t = useTranslate()

  useEffect(() => {
    const current = localStorage.getItem('shipmentsPage')
    setCurrentPage(current ? parseInt(current) : 1)
  }, [])

  const handleLocationChange = async (value: any, record: any, func: Function) => {
    if (record.id) {
      await supabaseClient
        .from('orders')
        .update({
          location: value,
          updated_by: supabaseClient.auth.user()?.email
        })
        .eq('id', record.id)
      const val = tableProps?.dataSource?.find(x => x.order_id === record.id) as any
      if (val && val.order) {
        val.order.location = value
      }
      func(true)
    }
  }

  return (
    <>
      {contextHolder}
      <List
        title={t('shipments.titles.list')}
        headerProps={{
          extra: (
            <>
              <Button
                onClick={generateTicket}
                style={{
                  color: '#00B6FF',
                  borderColor: '#00B6FF'
                }}
              >
                {t('shipments.actions.generateTicket')}
              </Button>
              <EditButton onClick={showChangeOrder}>
                {t('actions.changeOrdersStatus')}
              </EditButton>
              <ExportButton
                onClick={() =>
                  window.open(
                    `${API_URL}/list/items?orders=${selectedRows
                      .map((row) => row.order_id)
                      .join(';')}`,
                    '_blank',
                    'noopener,noreferrer'
                  )
                }
              >
                {t('actions.itemList')}
              </ExportButton>
              <ExportButton
                onClick={() =>
                  window.open(
                    `${API_URL}/list/items?orders=${selectedRows
                      .map((row) => row.order_id)
                      .join(';')}&format=excel`,
                    '_blank',
                    'noopener,noreferrer'
                  )
                }
              >
                {t('actions.itemListExcel')}
              </ExportButton>
              <ExportToExcelButton filename='Envios' jsonData={dataToExport(selectedRows.length > 0 ? selectedRows : tableProps.dataSource as any[])} />
            </>
          )
        }}
      >
        <Table
          {...tableProps}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange (page, pageSize) {
              setCurrentPage(page)
              setPageSize(pageSize)
              localStorage.setItem('shipmentsPage', page.toString() || '')
            }
          }}
          rowSelection={{
            selectedRowKeys: idRows,
            type: 'checkbox',
            onChange: (
              _selectedRowKeys: React.Key[],
              selectedRows: definitions['shipments'][],
              info: { type: string }
            ) => {
              if (info.type === 'all') {
                if (tableProps.dataSource?.length !== idRows.length && tableProps.dataSource && tableProps.dataSource?.length > 0) {
                  const ids = [...(tableProps.dataSource || [])]
                  setSelectedRows(ids)
                  setIdRows(ids.map((shipment: definitions['shipments']) => shipment.id))
                } else {
                  if (tableProps.dataSource && tableProps.dataSource?.length > 0 && idRows.length > 0 && tableProps.dataSource?.length === idRows.length) {
                    setSelectedRows([])
                    setIdRows([])
                  }
                }
              } else {
                setSelectedRows(selectedRows)
                setIdRows(_selectedRowKeys)
              }
            },
            getCheckboxProps: (record: definitions['shipments']) => ({
              id: record.id.toString()
            })
          }}
        >
          <Table.Column
            dataIndex="id"
            fixed="left"
            align="center"
            title={t('shipments.fields.id')}
            sorter
            render={(value, record: Shipment) => (
              <>
                {value}
                {record?.order?.comments && (
                  <Tooltip title={record?.order?.comments} placement='bottom'>
                    <Badge
                      style={{ fontSize: 20 }}
                      count={
                        <Icons.WarningOutlined style={{ color: '#f5222d' }} />
                      }
                    />
                  </Tooltip>
                )}
              </>
            )}
          />
          <Table.Column
            key="order_id"
            fixed="left"
            dataIndex="order_id"
            align="center"
            title={t('shipments.fields.order_id')}
            render={(orderId) =>
              orderId && (
                <ShowButton
                  style={{ height: 'fit-content' }}
                  onClick={() =>
                    window.open(
                      `/orders/show/${orderId}`,
                      '_blank',
                      'noopener,noreferrer'
                    )
                  }
                >
                  <br />
                  {orderId}
                </ShowButton>
              )
            }
            sorter
            filterDropdown={() => (
              <QuantityFilterDropdown
                field="order_id"
                filters={filters}
                setFilters={setFilters}
              />
            )}
          />
          <Table.Column
            key='order_id'
            dataIndex={['order', 'alias']}
            align="center"
            title={t('order.fields.alias')}
            sorter
            filterDropdown={(props: FilterDropdownProps) => (
              <SelectFilterDropdown
                props={props}
                entity='orders'
                optionLabel='alias'
                optionValue='id'
              />
            )}
          />
          <Table.Column
            key="order.status_id"
            dataIndex={['order', 'status', 'name']}
            align="center"
            title={t('shipments.fields.status')}
            render={(value) =>
              value && (
                <Tag color={getColorLabel(value)}>
                  {capitalizeFirstLetter(value)}
                </Tag>
              )
            }
            filterDropdown={(props: FilterDropdownProps) => {
              props.setSelectedKeys = setFilterStatusId
              props.clearFilters = () => {
                if (filters) {
                  setFilters([
                    ...filters.filter((x: any) => x.field !== 'order.status_id' && x.operator === 'in') as any,
                    ...[{ field: 'order.status_id', operator: 'in', value: [ADMITTED_STATUS, PREPARED_STATUS] }]
                  ])
                  setFilterStatusId(undefined)
                  tableQueryResult.refetch()
                }
              }
              props.confirm = () => {
                if (filterStatusId && filterStatusId.length > 0) {
                  if (filters && filters.find((x: any) => x.field === 'order.status_id' && x.operator === 'in')) {
                    const updatedFilters = [...filters]
                    const index = updatedFilters.findIndex((x: any) => x.field === 'order.status_id' && x.operator === 'in')
                    updatedFilters[index].value = filterStatusId

                    setFilters([...updatedFilters])
                  } else {
                    setFilters([...filters as any, ...[{ field: 'order.status_id', operator: 'in', value: filterStatusId }]])
                  }
                  tableQueryResult.refetch()
                }
              }
              return <SelectFilterDropdown
                props={props}
                entity='status'
                optionLabel='name'
                optionValue='id'
                sorters={[{
                  field: 'id',
                  order: 'asc'
                }]}
                excludeFieldsFilter={[
                  PENDING_PAYMENT_STATUS_TEXT,
                  REQUEST_STATUS_TEXT,
                  PROCESS_STATUS_TEXT,
                  CANCEL_STATUS_TEXT,
                  RETURN_STATUS_TEXT,
                  SUPPLEMENT_STATUS_TEXT,
                  NO_STOCK_STATUS_TEXT,
                  SHIPPED_STATUS_TEXT,
                  DELIVERED_STATUS_TEXT,
                  PENALIZATION_STATUS_TEXT,
                  PENALIZATION_STATUS_TEXT_SPA,
                  REFUND_STATUS_TEXT,
                  PARCIAL_RETURN_STATUS_TEXT,
                  DECREASE_RETURN_STATUS_TEXT,
                  INTERNAL_SPENDING_RETURN_STATUS_TEXT
                ]}
              />
            }}
          />
          <Table.Column
            key="order.syniva_price"
            dataIndex={['order', 'syniva_price']}
            align="center"
            title={t('order.fields.synivaPrice')}
            render={(value) => currency(value)}
            sorter
            filterDropdown={() => (
              <QuantityFilterDropdown
                field="order.syniva_price"
                filters={filters}
                setFilters={setFilters}
              />
            )}
          />
          <Table.Column
            key='order.package_number'
            dataIndex={['order', 'package_number']}
            align="center"
            title={t('order.fields.package_number')}
            sorter
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <Input type='text' />
              </FilterDropdown>
            )}
          />
          <Table.Column
            key='order.location'
            dataIndex={['order', 'location']}
            align="center"
            title={t('order.fields.location')}
            render={(value, record: any) => (
              <Space style={{ width: '200px', justifyContent: 'center' }}>
                <EditableRowField key={value} record={record.order} handleSave={handleLocationChange} />
              </Space>
            )}
            sorter
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <Input type='text' />
              </FilterDropdown>
            )}
          />
          <Table.Column
            key="order.shipping_method_id"
            dataIndex={['order', 'shipping_method', 'name']}
            title={t('shipments.fields.shipmentMethod')}
            align="center"
            sorter
            filterDropdown={(props: FilterDropdownProps) => (
              <SelectFilterDropdown
                props={props}
                entity="shipment_methods"
                optionLabel="name"
                optionValue="id"
                filters={[
                  {
                    field: 'deleted',
                    operator: 'eq',
                    value: false
                  }
                ]}
              />
            )}
          />
          <Table.Column
            key="order.supplements"
            dataIndex={['order', 'supplements', '0', 'billable_volume']}
            title={t('supplement.fields.billableVolume')}
            align="center"
          />
          <Table.Column
            key="tracking_id"
            dataIndex="tracking_id"
            title={t('shipments.fields.trackingId')}
            align="center"
            sorter
            filterDropdown={(props: FilterDropdownProps) => (
              <SelectFilterDropdown
                props={props}
                entity="shipments"
                optionLabel="tracking_id"
                optionValue="tracking_id"
                type='string'
                filters={[
                  {
                    field: 'tracking_id',
                    operator: 'nnull',
                    value: 'null'
                  }
                ]}
              />
            )}
          />
          <Table.Column
            key="shipper_type"
            dataIndex="shipper_type"
            title={t('shipments.fields.type')}
            align="center"
            width={200}
            sorter
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}>
                <Select mode="multiple" style={{ minWidth: 150 }}>
                  <Option value={t('shipments.methods.tipsaExpress')}>{t('shipments.methods.tipsaExpress')}</Option>
                  <Option value={t('shipments.methods.tipsaStandard')}>{t('shipments.methods.tipsaStandard')}</Option>
                  <Option value={t('shipments.methods.ontime')}>{t('shipments.methods.ontime')}</Option>
                  <Option value={t('shipments.methods.correosExpress')}>{t('shipments.methods.correosExpress')}</Option>
                  <Option value={t('shipments.methods.correosStandard')}>{t('shipments.methods.correosStandard')}</Option>
                </Select>
              </FilterDropdown>
            )}
          />
          <Table.Column
            key="shipped_by"
            dataIndex="shipped_by"
            title={t('shipments.fields.shipperType')}
            align="center"
            width={200}
            sorter
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}>
                <Select mode="multiple" style={{ minWidth: 150 }}>
                  <Option value={t('shipments.methods.tipsaMAINLAND')}>{t('shipments.methods.tipsaMAINLAND')}</Option>
                  <Option value={t('shipments.methods.tipsaGC')}>{t('shipments.methods.tipsaGC')}</Option>
                  <Option value={t('shipments.methods.tipsaTF')}>{t('shipments.methods.tipsaTF')}</Option>
                  <Option value={t('shipments.methods.ontime')}>{t('shipments.methods.ontime')}</Option>
                  <Option value={t('shipments.methods.correos')}>{t('shipments.methods.correos')}</Option>
                </Select>
              </FilterDropdown>
            )}
          />
          <Table.Column
            key="delivery_note"
            dataIndex="delivery_note"
            title={t('shipments.fields.deliveryNote')}
            align="center"
            sorter
            filterDropdown={(props: FilterDropdownProps) => (
              <SelectFilterDropdown
                props={props}
                entity="shipments"
                optionLabel="delivery_note"
                optionValue="delivery_note"
                filters={[
                  {
                    field: 'delivery_note',
                    operator: 'nnull',
                    value: 'null'
                  }
                ]}
              />
            )}
          />
          <Table.Column
            key="processed_at"
            dataIndex="processed_at"
            title={t('shipments.fields.processed_at')}
            align="center"
            sorter
            render={(value) =>
              value
                ? (
                <DateField
                  format={DEFAULT_DATE_TIME_FORMAT}
                  value={value}
                ></DateField>
                  )
                : (
                    ' '
                  )
            }
            filterDropdown={() => (
              <DateFilterDropdown
                field="processed_at"
                filters={filters}
                setFilters={setFilters}
              />
            )}
          />
          {/* HIDDEN FOR NOW... */}
          {/* <Table.Column
          dataIndex="extra_cost"
          title={t('shipments.fields.extraCost')}
          render={(value) => currency(value)}
          align="center"
        /> */}
          <Table.Column<definitions['shipments']>
            fixed="right"
            title={t('table.actions')}
            dataIndex="actions"
            key="actions"
            align="center"
            render={(_, record: any) => (
              <>
                <EditButton
                  size="small"
                  hideText
                  recordItemId={record.id}
                  style={{ margin: '3px' }}
                />
                <Button
                  onClick={() => {
                    setOrderId(record.order_id || 0)
                    setCurrentOrderInfo(record || null)
                    if (record?.tracking_id !== null) {
                      setAdviseText(t('supplement.advise.duplicatedPrepare'))
                      showAdvice()
                    } else if (record.order.shipping_method.name.toLowerCase().includes('express')) {
                      setAdviseText(t('supplement.advise.dangerousWare'))
                      showAdvice()
                    } else {
                      show()
                    }
                  }}
                  style={{
                    color: '#8dce5a',
                    borderColor: '#8dce5a',
                    margin: '3px'
                  }}
                  size="small"
                >
                  {t('shipments.actions.prepare')}
                </Button>
                <Button
                  onClick={() => viewTicket(record)}
                  style={{
                    color: '#00B6FF',
                    borderColor: '#00B6FF',
                    margin: '3px'
                  }}
                  size="small"
                >
                  {t('shipments.actions.showTicket')}
                </Button>
                <Button
                  style={{ margin: '3px' }}
                  size="small"
                  onClick={async () => {
                    if (record?.order?.invoice_date && record?.order?.invoice) {
                      const { data } = await supabaseClient.storage.from('invoices').list(undefined, { limit: 1, search: `${record?.order?.invoice}` })

                      if (data?.length === 0) {
                        window.open(`${API_URL}/billings/pdf/${record.order_id}`, '_blank', 'noopener,noreferrer')
                      } else {
                        const nowDate = new Date(Date.now())
                        const invDate = new Date(record?.order?.invoice_date)
                        if (nowDate.getFullYear() === invDate.getFullYear()) {
                          if (nowDate.getMonth() + 1 > invDate.getMonth() + 1) {
                            window.open(`${API_URL}/billings/show/${record.order_id || 0}`, '_blank', 'noopener,noreferrer')
                          } else {
                            window.open(`${API_URL}/billings/pdf/${record.order_id || 0}`, '_blank', 'noopener,noreferrer')
                          }
                        } else {
                          window.open(`${API_URL}/billings/show/${record.order_id || 0}`, '_blank', 'noopener,noreferrer')
                        }
                      }
                    } else {
                      window.open(`${API_URL}/billings/pdf/${record.order_id || 0}`, '_blank', 'noopener,noreferrer')
                    }
                  }}
                >
                  {t('buttons.viewInvoice')}
                </Button>
              </>
            )}
          />
        </Table>
        <ProcessModal
          close={close}
          modalProps={modalProps}
          orderId={orderId}
          setOrderId={setOrderId}
          currentOrderInfo={currentOrderInfo}
          setCurrentOrderInfo={setCurrentOrderInfo}
          tableQuery={tableQueryResult}
        />

        <ModalChangeOrdersStatus
          modalProps={modalPropsChangeOrder}
          close={closeChangeOrder}
          selectedRows={selectedRows}
          tableQuery={tableQueryResult}
        />
        <AdviceModal
          modalProps={modalPropsAdvice}
          close={closeAdvice}
          show={showAdvice}
          infoText={adviseText}
          okFunction={() => {
            show()
          }}
        />
      </List>
    </>
  )
}
